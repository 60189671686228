<template>
  <div>
    <v-dialog v-model="avatarDialog" persistent scrollable max-width="880">
      <v-card>
        <v-toolbar color="indigo" dense dark><h3>Avatar</h3></v-toolbar>
        <v-card-text>
          <v-toolbar flat class="mt-3" dense><h3>Avatar Anda</h3></v-toolbar>
          <v-divider></v-divider>
          <div class="d-flex flex-wrap px-5 py-2">
            <section
              class="rafatar d-flex flex-column align-center mx-2"
              v-for="roku in avaPublic"
              :key="roku.id"
              @click="changeAva(roku, 'publik', 'free')"
            >
              <v-avatar size="100">
                <img
                  :src="`${env}/avatar/${roku.file}`"
                  alt="dva"
                  class="dva"
                />
              </v-avatar>
              <v-chip class="ma-2 white--text" color="indigo" label>
                Pakai
              </v-chip>
            </section>
          </div>
          <v-divider></v-divider>
          <div class="d-flex flex-wrap px-5 py-2">
            <section
              class="rafatar d-flex flex-column align-center mx-2"
              v-for="roku in avaPrivate"
              :key="roku.id"
              @click="changeAva(roku, 'private', 'free')"
            >
              <v-avatar size="100">
                <img
                  :src="`${env}/avatar/${roku.file}`"
                  alt="dva"
                  class="dva"
                />
              </v-avatar>
              <v-chip class="ma-2 white--text" color="indigo" label>
                Pakai
              </v-chip>
            </section>
          </div>
          <v-toolbar flat class="mt-3" dense><h3>List Avatar</h3></v-toolbar>
          <v-divider></v-divider>
          <div class="d-flex px-5 py-2">
            <section
              class="rafatar d-flex flex-column align-center mx-2"
              v-for="roku in gojuu"
              :key="roku.id"
              @click="changeAva(roku, 'publik', 'paid')"
            >
              <v-avatar size="100">
                <img
                  :src="`${env}/avatar/${roku.file}`"
                  alt="dva"
                  class="dva"
                />
              </v-avatar>
              <v-chip class="ma-2 white--text" color="indigo" label>
                <v-icon color="yellow accent-4"
                  >mdi-currency-usd-circle-outline</v-icon
                >
                {{ roku.poin }} poin
              </v-chip>
            </section>
          </div>
          <v-divider></v-divider>
          <div class="d-flex px-5 py-2">
            <section
              class="rafatar d-flex flex-column align-center mx-2"
              v-for="roku in hyakuGojuu"
              :key="roku.id"
              @click="changeAva(roku, 'publik', 'paid')"
            >
              <v-avatar size="100">
                <img
                  :src="`${env}/avatar/${roku.file}`"
                  alt="dva"
                  class="dva"
                />
              </v-avatar>
              <v-chip class="ma-2 white--text" color="indigo" label>
                <v-icon color="yellow accent-4"
                  >mdi-currency-usd-circle-outline</v-icon
                >
                {{ roku.poin }} poin
              </v-chip>
            </section>
          </div>
          <v-divider></v-divider>
          <div class="d-flex px-5 py-2">
            <section
              class="rafatar d-flex flex-column align-center mx-2"
              v-for="roku in niHyakuGojuu"
              :key="roku.id"
              @click="changeAva(roku, 'publik', 'paid')"
            >
              <v-avatar size="100">
                <img
                  :src="`${env}/avatar/${roku.file}`"
                  alt="dva"
                  class="dva"
                />
              </v-avatar>
              <v-chip class="ma-2 white--text" color="indigo" label>
                <v-icon color="yellow accent-4"
                  >mdi-currency-usd-circle-outline</v-icon
                >
                {{ roku.poin }} poin
              </v-chip>
            </section>
          </div>
          <v-divider></v-divider>
          <div class="d-flex px-5 py-2">
            <section
              class="rafatar d-flex flex-column align-center mx-2"
              v-for="roku in sanByaku"
              :key="roku.id"
              @click="changeAva(roku, 'publik', 'paid')"
            >
              <v-avatar size="100">
                <img
                  :src="`${env}/avatar/${roku.file}`"
                  alt="dva"
                  class="dva"
                />
              </v-avatar>
              <v-chip class="ma-2 white--text" color="indigo" label>
                <v-icon color="yellow accent-4"
                  >mdi-currency-usd-circle-outline</v-icon
                >
                {{ roku.poin }} poin
              </v-chip>
            </section>
          </div>
          <!-- <v-divider></v-divider> -->
          <v-toolbar flat class="mt-3" dense><h3>Custom avatar</h3></v-toolbar>
          <v-divider></v-divider>
          <div class="px-5 py-2">
            <section class="d-flex flex-column align-center mx-2">
              <div class="temp" v-if="!customAva">
                <div class="d-flex flex-column">
                  <v-icon>mdi-camera</v-icon>
                  <small><b>Unggah foto</b></small>
                </div>
              </div>
              <v-avatar size="100" color="red" v-if="customAva">
                <img :src="customAva" alt="dva" class="dva" />
              </v-avatar>
              <v-btn color="indigo" class="mt-1" dark small>
                <div class="upload-btn-wrapper add-width">
                  <div>
                    <v-icon class="mr-1"> mdi-pencil-box </v-icon>
                    Pilih foto
                    <input
                      type="file"
                      name="myfile"
                      @change="uploadAva($event)"
                    />
                  </div>
                </div>
              </v-btn>
              <v-chip class="ma-2 white--text" color="indigo" label>
                <v-icon color="yellow accent-4"
                  >mdi-currency-usd-circle-outline</v-icon
                >
                600 poin
              </v-chip>
            </section>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="$emit('false')">
            Batal
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="dialogPlay">
      <DialogPlay
        v-bind:dialogPlay="dialogPlay"
        v-bind:goTo="goTo"
        v-on:false="dialogFalse"
      />
    </div>
    <v-dialog v-model="progress" hide-overlay persistent width="250">
      <v-card class="indigo" dark>
        <v-card-text>
          silahkan tunggu...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { LIST_AVATAR, AVATAR_PRIVATE } from "../../../graphql/graphql";
import { EDIT_STUDENT, UPLOAD_AVATAR } from "../../../graphql/Student";
import { mapState } from "vuex";
import DialogPlay from "../../_base/dialogPlay";

export default {
  apollo: {
    allAvatar: {
      query: LIST_AVATAR,
    },
    allAvatarPrivate: {
      query: AVATAR_PRIVATE,
    },
  },
  name: "chooseAvatar",
  components: {
    DialogPlay,
  },
  props: ["avatarDialog", "myAvatar"],
  computed: {
    ...mapState(["poin", "userProfile", "pathGame"]),
    avaPublic() {
      let data = null;
      let mine = null;
      if (this.allAvatar) {
        mine = this.myAvatar.filter((kora) => {
          return kora.tipe == "publik";
        });
        mine = mine.map((x) => {
          return x.avatar_id.toString();
        });
        data = this.allAvatar.filter((kyoshi) => {
          return mine.indexOf(kyoshi.id) > -1;
        });
      }
      return data;
    },
    avaPrivate() {
      let data = null;
      let mine = null;
      if (this.allAvatarPrivate) {
        mine = this.myAvatar.filter((kora) => {
          return kora.tipe == "private";
        });
        mine = mine.map((x) => {
          return x.avatar_id.toString();
        });
        data = this.allAvatarPrivate.filter((kyoshi) => {
          return mine.indexOf(kyoshi.id) > -1;
        });
      }
      return data;
    },
    gojuu() {
      let data = null;
      let justID = null;
      if (this.allAvatar) {
        data = this.allAvatar.filter((kyoshi) => {
          return kyoshi.poin == 50;
        });
        justID = this.avaPublic.map((x) => {
          return x.id;
        });
        data = data.filter((kyoshi) => {
          return justID.indexOf(kyoshi.id) === -1;
        });
      }
      return data;
    },
    hyakuGojuu() {
      let data = null;
      let justID = null;
      if (this.allAvatar) {
        data = this.allAvatar.filter((roku) => {
          return roku.poin == 150;
        });
        justID = this.avaPublic.map((x) => {
          return x.id;
        });
        data = data.filter((kyoshi) => {
          return justID.indexOf(kyoshi.id) === -1;
        });
      }
      return data;
    },
    niHyakuGojuu() {
      let data = null;
      let justID = null;
      if (this.allAvatar) {
        data = this.allAvatar.filter((aang) => {
          return aang.poin == 250;
        });
        justID = this.avaPublic.map((x) => {
          return x.id;
        });
        data = data.filter((kyoshi) => {
          return justID.indexOf(kyoshi.id) === -1;
        });
        console.log(data);
      }
      return data;
    },
    sanByaku() {
      let data = null;
      if (this.allAvatar) {
        data = this.allAvatar.filter((kora) => {
          return kora.poin == 300;
        });
        data = data.filter((kyoshi) => {
          return this.avaPublic.indexOf(kyoshi.id) === -1;
        });
      }
      return data;
    },
  },
  data() {
    return {
      allAvatar: null,
      allAvatarPrivate: null,
      dialog3: false,
      idAva: null,
      dialogPlay: false,
      customAva: null,
      progress: false,
      files: null,
      goTo: "",
      env: process.env.VUE_APP_GRAPHQL,
    };
  },
  methods: {
    changeAva(ava, tipe, cost) {
      if (cost == "paid") {
        if (this.poin >= ava.poin) {
          this.mutateUpdateAva(ava, tipe);
        } else {
          this.goTo = this.pathGame.unlock_avatar;
          this.dialogPlay = true;
        }
      } else if (cost == "free") {
        this.mutateUpdateAva(ava, tipe);
      }
    },

    mutateUpdateAva(ava, tipe) {
      Swal.fire({
        title: "Yakin akan mengubah avatar?",
        imageUrl: `${process.env.VUE_APP_GRAPHQL}/avatar/${ava.file}`,
        imageWidth: 200,
        imageHeight: 200,
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          const id = parseInt(this.userProfile.id);
          const id_profile = parseInt(this.userProfile.student.id);
          const avatar = parseInt(ava.id);
          const tipe_avatar = tipe;
          this.$apollo
            .mutate({
              mutation: EDIT_STUDENT,
              variables: {
                id: id,
                connect: id,
                id_profile: id_profile,
                avatar: avatar,
                tipe_avatar: tipe_avatar,
              },
            })
            .then(() => {
              this.$emit("refetch");
              Swal.fire({
                icon: "success",
                title: "Avatar berhasil diubah",
                timer: 2000,
                position: "top",
                toast: true,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },

    uploadAva(e) {
      const file = e.target.files[0];
      this.files = file;
      if (this.poin < 600) {
        this.goTo = this.pathGame.unlock_avatar;
        this.dialogPlay = true;
      } else {
        if (file.size > 250000) {
          Swal.fire({
            icon: "error",
            title: "ukuran foto max 250kb",
            timer: 2000,
            position: "top",
            toast: true,
          });
        } else {
          const fr = new FileReader();
          fr.onload = (f) => {
            Swal.fire({
              title: "Yakin akan mengubah avatar?",
              imageUrl: f.target.result,
              imageHeight: "150px",
              customClass: {
                image: "radianss",
              },
              showCancelButton: true,
              confirmButtonColor: "#3F51B5",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ya",
              cancelButtonText: "Batal",
            }).then((result) => {
              if (result.isConfirmed) {
                this.customAva = f.target.result;
                this.uploadCustomAva(this.files);
              }
            });
          };
          fr.readAsDataURL(file);
        }
      }
    },

    uploadCustomAva(data) {
      const user_id = parseInt(localStorage.getItem("id"));
      const file = data;
      const poin = 600;
      this.progress = true;
      this.$apollo
        .mutate({
          mutation: UPLOAD_AVATAR,
          variables: {
            user_id: user_id,
            file: file,
            poin: poin,
          },
          context: {
            hasUpload: true,
          },
        })
        .then((data) => {
          console.log(data.data.uploadPrivateAvatar);
          this.progress = false;
          this.$emit("refetch");
          Swal.fire({
            icon: "success",
            title: "Avatar berhasil diubah",
            timer: 2000,
            position: "top",
            toast: true,
          });
        })
        .catch((err) => {
          console.log(err.graphQLErrors);
          this.progress = false;
        });
    },
    dialogFalse() {
      this.dialogPlay = false;
    },
  },
};
</script>

<style scoped>
.rafatar {
  cursor: pointer;
}
.dva {
  object-fit: cover;
}
.temp {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px black dotted;
}

/* input file custom */
.upload-btn-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
/* end */
.radianss {
  border-radius: 50% !important;
}
</style>
