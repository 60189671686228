<template>
  <div>
    <UpdateProfileStudent v-if="role == 4" />
    <UpdateProfileMentor v-if="role == 3" />
    <UpdateprofileTeacher v-if="role == 2" />
  </div>
</template>

<script>
import UpdateProfileStudent from "../Student/editProfileStudent";
import UpdateprofileTeacher from "../Teacher/editProfileTeacher";
import UpdateProfileMentor from "../Mentor/editProfileMentor";
export default {
  name: "MainEditProfile",
  components: {
    UpdateProfileStudent,
    UpdateprofileTeacher,
    UpdateProfileMentor
  },
  data() {
    return {
      role: localStorage.getItem("role_id")
    };
  }
};
</script>

<style></style>
