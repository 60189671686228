import gql from "graphql-tag";

//  CREATE teacher
export const ADD_TEACHER = gql`
  mutation(
    $name: String!
    $nama_lengkap: String!
    $email: String!
    $password: String!
    $password_no_hash: String
    $role_id: roleId!
    $created_by: Int!
  ) {
    createTeacher(
      input: {
        user: {
          create: {
            name: $name
            nama_lengkap: $nama_lengkap
            email: $email
            password: $password
            password_no_hash: $password_no_hash
            role_id: $role_id
            created_by: $created_by
          }
        }
      }
    ) {
      id
      user_id
      user {
        id
        name
        nama_lengkap
        email
      }
    }
  }
`;
// READ peserta
export const GET_TEACHER = gql`
  query($page: Int!, $first: Int!, ) {
    scopeTeacher(
      page: $page
      first: $first
      # search: { search: $search, role_id: 2 }
    ) {
      data {
        id
        name
        nama_lengkap
        email
      }
      paginatorInfo {
        currentPage
        total
      }
    }
  }
`;
//EDIT TEACHER
export const EDIT_TEACHER = gql`
  mutation(
    $id: ID!
    $connect: ID!
    $id_profile: ID!
    $nama_lengkap: String
    $email: String
    $password: String
    $nik: String
    $nip: String
    $golongan: String
    $pangkat: String
    $jabatan: String
    $bidang_keahlian: String
    $pendidikan_terakhir: pendidikanTerakhir
    $unit_kerja: String
    $instansi: String
    $jenis_kelamin: String
    $agama: String
    $tempat_lahir: String
    $tanggal_lahir: Date
    $alamat_tinggal: String
    $alamat_ktp: String
    $avatar: Int
    $tipe_avatar: String
  ) {
    updateTeacher(
      input: {
        id: $id
        nama_lengkap: $nama_lengkap
        email: $email
        password: $password
        avatar: $avatar
        tipe_avatar: $tipe_avatar
        pembimbing: {
          connect: $connect
          update: {
            id: $id_profile
            nik: $nik
            nip: $nip
            golongan: $golongan
            pangkat: $pangkat
            jabatan: $jabatan
            bidang_keahlian: $bidang_keahlian
            pendidikan_terakhir: $pendidikan_terakhir
            unit_kerja: $unit_kerja
            instansi: $instansi
            jenis_kelamin: $jenis_kelamin
            agama: $agama
            tempat_lahir: $tempat_lahir
            tanggal_lahir: $tanggal_lahir
            alamat_tinggal: $alamat_tinggal
            alamat_ktp: $alamat_ktp
          }
        }
      }
    ) {
      id
      name
      nama_lengkap
      email
      tipe_avatar
    }
  }
`;
// DELETE peserta
export const DELET_TEACHER = gql`
  mutation($id: ID!) {
    deleteTeacher(id: $id) {
      id
    }
  }
`;

//GET EVENT BY ID
export const GET_SUBMISSION = gql`
  query($id: ID!) {
    pelatihanById(id: $id) {
      id
      nama
      grup {
        id
        nama
        user {
          id
          nama_lengkap
          data_tugas_individu {
            id
            file
          }
        }
      }
    }
  }
`;

// READ peserta
export const COUNT_TEACHER = gql`
  query($page: Int!, $first: Int!) {
    scopeTeacher(page: $page, first: $first) {
      paginatorInfo {
        total
      }
    }
  }
`;
//IMPORT PESERTA
export const IMPORT_PESERTA = gql`
  mutation($import: Upload, $grup_id: Int) {
    importStudent(import: $import, grup_id: $grup_id) {
      id
    }
  }
`;
