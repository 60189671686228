<template>
  <div class="container-update">
    <v-progress-linear
      :active="loadData"
      :indeterminate="loadData"
      absolute
      top
      color="orange"
    ></v-progress-linear>
    <div class="d-flex" v-if="!loadData">
      <div class="card-profile">
        <div class="tag">Foto</div>
        <div class="temp-rafatar">
          <v-avatar size="130">
            <img :src="avatar ? avatar : fotoDummy" alt="pp" class="dva" />
          </v-avatar>
        </div>
        <div class="upload-btn-wrapper mt-3">
          <v-btn class="mx-2 ma-1" dark small color="indigo">
            Ganti foto
            <v-icon dark class="ml-2"> mdi-pencil </v-icon>
          </v-btn>
          <input type="file" name="myfile" @change="uploadAva($event)" />
        </div>
      </div>
      <div class="temp-form-edit">
        <div class="tag">Form biodata</div>
        <div class="temp pa-5 ma-3" v-if="!loadData">
          <v-form ref="form" lazy-validation>
            <v-text-field
              v-model="profile.nama_lengkap"
              label="Nama Lengkap"
              required
            ></v-text-field>
            <v-text-field
              v-model="profile.pembimbing.nik"
              label="NIK"
              required
            ></v-text-field>
            <v-text-field
              v-model="profile.pembimbing.nip"
              label="NIP"
              required
            ></v-text-field>
            <v-text-field
              v-model="profile.pembimbing.golongan"
              label="Golongan"
              required
            ></v-text-field>
            <v-text-field
              v-model="profile.pembimbing.pangkat"
              label="Pangkat"
              required
            ></v-text-field>
            <v-text-field
              v-model="profile.pembimbing.jabatan"
              label="Jabatan"
              required
            ></v-text-field>
            <v-text-field
              v-model="profile.pembimbing.unit_kerja"
              label="Unit kerja"
              required
            ></v-text-field>
            <v-text-field
              v-model="profile.pembimbing.instansi"
              label="Instansi"
              required
            ></v-text-field>
            <v-text-field
              v-model="profile.pembimbing.tempat_lahir"
              label="Tempat Lahir"
              required
            ></v-text-field>
            <!-- calendar -->
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="profile.pembimbing.tanggal_lahir"
                  label="Tanggal lahir"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="profile.pembimbing.tanggal_lahir"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
            <!-- calender end -->
            <v-text-field
              v-model="profile.pembimbing.alamat_tinggal"
              label="Alamat tinggal"
              required
            ></v-text-field>
            <v-text-field
              v-model="profile.pembimbing.bidang_keahlian"
              label="Bidang keahlian"
              required
            ></v-text-field>
            <v-text-field
              v-model="profile.pembimbing.alamat_ktp"
              label="Alamat ktp"
              required
            ></v-text-field>
            <v-select
              :items="lastEducation"
              item-text="state"
              item-value="val"
              v-model="profile.pembimbing.pendidikan_terakhir"
              label="Pendidikan terakhir"
            ></v-select>
            <v-select
              :items="sex"
              item-text="state"
              item-value="val"
              v-model="profile.pembimbing.jenis_kelamin"
              label="Jenis kelamin"
            ></v-select>
            <v-select
              :items="agama"
              item-text="state"
              v-model="profile.pembimbing.agama"
              item-value="val"
              label="Agama"
            ></v-select>
            <v-text-field
              v-model="profile.email"
              label="E-mail"
              required
            ></v-text-field>

            <v-btn color="success" class="mr-4" @click="updateTeacher">
              Simpan
            </v-btn>
          </v-form>
        </div>
      </div>
    </div>
    <v-dialog v-model="progress" hide-overlay persistent width="250">
      <v-card class="indigo" dark>
        <v-card-text>
          silahkan tunggu...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GET_USER } from "../../graphql/graphql";
import { mapState } from "vuex";
import Swal from "sweetalert2";
import { EDIT_TEACHER } from "../../graphql/Teacher";
import { UPLOAD_AVATAR } from "../../graphql/Student";

export default {
  apollo: {
    profile: {
      query: GET_USER,
      error(error) {
        return error.graphQLErrors[0].message;
      }
    }
  },
  name: "updateProfile",

  created() {},
  computed: {
    avatar() {
      let pp = null;
      if (this.profile.data_avatar) {
        pp = `${process.env.VUE_APP_GRAPHQL}/avatar/${this.profile.data_avatar.file}`;
      }
      return pp;
    },
    loadData() {
      return this.$apollo.queries.profile.loading;
    },
    ...mapState(["precentage"]),
    fotoDummy() {
      const pp = "https://dashboard.anargya.id/img/fotoprofile.jpg";
      return pp;
    }
  },
  data() {
    return {
      value: null,
      profile: null,
      allAvatar: null,
      date: new Date().toISOString().substr(0, 10),
      menu1: false,
      files: null,
      progress: false,
      avatarDialog: false,
      // v-model nama
      sex: [
        { state: "Laki - laki", val: "Lakilaki" },
        { state: "Perempuan", val: "Perempuan" }
      ],
      agama: [
        { state: "Islam", val: "Islam" },
        { state: "Protestan", val: "Protestan" },
        { state: "Katholik", val: "Katholik" },
        { state: "Hindu", val: "Hindu" },
        { state: "Budha", val: "Budha" },
        { state: "Khonghucu", val: "Khonghucu" }
      ],
      lastEducation: [
        { state: "SMA/Sederajat", val: "SMA" },
        { state: "D3", val: "D3" },
        { state: "S1", val: "S1" },
        { state: "S2", val: "S2" },
        { state: "S3", val: "S3" }
      ],
      dialog: false
    };
  },
  methods: {
    uploadAva(e) {
      const file = e.target.files[0];
      this.files = file;
      if (file.size > 250000) {
        Swal.fire({
          icon: "error",
          title: "ukuran foto max 250kb",
          timer: 2000,
          position: "top",
          toast: true
        });
      } else {
        const fr = new FileReader();
        fr.onload = f => {
          Swal.fire({
            title: "Yakin dengan pilihan Anda?",
            imageUrl: f.target.result,
            imageHeight: 200,
            customClass: {
              image: "radianss"
            },
            showCancelButton: true,
            confirmButtonColor: "#3F51B5",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ya",
            cancelButtonText: "Batal"
          }).then(result => {
            if (result.isConfirmed) {
              this.customAva = f.target.result;
              this.uploadCustomAva(this.files);
            }
          });
        };
        fr.readAsDataURL(file);
      }
    },

    uploadCustomAva(data) {
      const user_id = parseInt(localStorage.getItem("id"));
      const file = data;
      const poin = 0;
      this.progress = true;
      this.$apollo
        .mutate({
          mutation: UPLOAD_AVATAR,
          variables: {
            user_id: user_id,
            file: file,
            poin: poin
          },
          context: {
            hasUpload: true
          }
        })
        .then(() => {
          this.progress = false;
          this.profileRefetch();
          Swal.fire({
            icon: "success",
            title: "Avatar berhasil diubah",
            timer: 2000,
            position: "top",
            toast: true
          });
        })
        .catch(err => {
          console.log(err.graphQLErrors);
          this.progress = false;
        });
    },

    profileRefetch() {
      this.$apollo.queries.profile.refetch();
      this.dialogFalse();
    },
    updateTeacher() {
      const id = parseInt(this.profile.id);
      const id_profile = parseInt(this.profile.pembimbing.id);
      this.$apollo
        .mutate({
          mutation: EDIT_TEACHER,
          variables: {
            id: id,
            nama_lengkap: this.profile.nama_lengkap,
            tipe_avatar: "private",
            email: this.profile.email,
            id_profile: id_profile,
            connect: id,
            no_peserta: this.profile.pembimbing.no_peserta,
            nik: this.profile.pembimbing.nik,
            nip: this.profile.pembimbing.nip,
            golongan: this.profile.pembimbing.golongan,
            pangkat: this.profile.pembimbing.pangkat,
            jabatan: this.profile.pembimbing.jabatan,
            unit_kerja: this.profile.pembimbing.unit_kerja,
            instansi: this.profile.pembimbing.instansi,
            bidang_keahlian: this.profile.pembimbing.bidang_keahlian,
            pendidikan_terakhir: this.profile.pembimbing.pendidikan_terakhir,
            jenis_kelamin: this.profile.pembimbing.jenis_kelamin,
            agama: this.profile.pembimbing.agama,
            tempat_lahir: this.profile.pembimbing.tempat_lahir,
            tanggal_lahir: this.profile.pembimbing.tanggal_lahir,
            alamat_tinggal: this.profile.pembimbing.alamat_tinggal,
            alamat_ktp: this.profile.pembimbing.alamat_ktp
          }
        })
        .then(() => {
          this.profileRefetch();
          Swal.fire({
            icon: "success",
            title: "Profile telah terupdate",
            showConfirmButton: false,
            timer: 3000,
            position: "top",
            toast: true
          }).then(() => {});
        })
        .catch(err => {
          console.log(err);
          // return err;
        });
    }
  }
};
</script>

<style>
.container-update {
  width: 100%;
  background: #f4f6f9;
  min-height: 90vh;
  padding: 10px;
}
.card-profile {
  width: 25%;
  display: block;
  height: min-content;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.22);
  margin: 10px;
  padding: 10px;
}
.temp-form-edit {
  width: 75%;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.22);
  padding: 10px;
  margin: 10px;
}
.temp-rafatar {
  width: 100%;
  display: flex;
  justify-content: center;
}
.tag {
  position: absolute;
  display: inline-block;
  padding: 10px 15px;
  background: #53af50;
  border-radius: 5px;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.22);
  color: #fff;
  margin-top: -30px;
}
.point {
  color: #5c73f2;
}
.dva {
  object-fit: cover;
}

/* input file custom */
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-content: center;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  opacity: 0;
}
/* end */
</style>
