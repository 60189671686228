<template>
  <div class="container-update">
    <v-progress-linear
      :active="loadData"
      :indeterminate="loadData"
      absolute
      top
      color="orange"
    ></v-progress-linear>
    <div class="d-flex" v-if="!loadData">
      <div class="card-profile">
        <div class="tag">Avatar</div>
        <div class="temp-rafatar">
          <v-progress-circular
            :size="150"
            :width="10"
            :color="precentage < 50 ? 'red' : 'light-green accent-4'"
            v-model="precentage"
          >
            <v-avatar size="130">
              <img :src="avatar ? avatar : fotoDummy" alt="pp" class="dva" />
            </v-avatar>
          </v-progress-circular>
        </div>
        <div class="info-user d-flex align-center flex-column">
          <div class="value-profile ma-2">
            <h4>{{ precentage }}%</h4>
          </div>
          <ChooseAvatar
            v-bind:avatarDialog="avatarDialog"
            v-bind:myAvatar="myAvatar"
            v-bind:dataAva="dataAva"
            v-on:false="dialogFalse()"
            v-on:refetch="profileRefetch()"
          />
          <v-btn
            class="mx-2 ma-1"
            dark
            small
            color="indigo"
            @click="avatarDialog = true"
          >
            Ganti Avatar
            <v-icon dark class="ml-2"> mdi-pencil </v-icon>
          </v-btn>
          <div class="point">
            <h3>{{ profile.student.poin }} Point</h3>
          </div>
        </div>
      </div>
      <div class="temp-form-edit">
        <div class="tag">Form biodata</div>
        <div class="temp pa-5 ma-3" v-if="!loadData">
          <v-form ref="form" lazy-validation>
            <v-text-field
              v-model="profile.nama_lengkap"
              label="Nama Lengkap"
              required
            ></v-text-field>
            <v-text-field
              v-model="profile.student.nik"
              label="NIK"
              required
            ></v-text-field>
            <v-text-field
              v-model="profile.student.nip"
              label="NIP"
              required
            ></v-text-field>
            <v-text-field
              v-model="profile.student.no_peserta"
              label="Nomor peserta"
              required
            ></v-text-field>
            <v-text-field
              v-model="profile.student.golongan"
              label="Golongan"
              required
            ></v-text-field>
            <v-text-field
              v-model="profile.student.pangkat"
              label="Pangkat"
              required
            ></v-text-field>
            <v-text-field
              v-model="profile.student.jabatan"
              label="Jabatan"
              required
            ></v-text-field>
            <v-text-field
              v-model="profile.student.unit_kerja"
              label="Unit kerja"
              required
            ></v-text-field>
            <v-text-field
              v-model="profile.student.instansi"
              label="Instansi"
              required
            ></v-text-field>
            <v-text-field
              v-model="profile.student.tempat_lahir"
              label="Tempat Lahir"
              required
            ></v-text-field>
            <!-- calendar -->
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="profile.student.tanggal_lahir"
                  label="Tanggal lahir"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="profile.student.tanggal_lahir"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
            <!-- calender end -->
            <v-text-field
              v-model="profile.student.alamat_tinggal"
              label="Alamat tinggal"
              required
            ></v-text-field>
            <v-text-field
              v-model="profile.student.alamat_ktp"
              label="Alamat ktp"
              required
            ></v-text-field>
            <v-select
              :items="sex"
              item-text="state"
              item-value="val"
              v-model="profile.student.jenis_kelamin"
              label="Jenis kelamin"
            ></v-select>
            <v-select
              :items="agama"
              item-text="state"
              v-model="profile.student.agama"
              item-value="val"
              label="Agama"
            ></v-select>
            <v-text-field
              v-model="profile.email"
              label="E-mail"
              required
            ></v-text-field>

            <v-btn color="success" class="mr-4" @click="updatePeserta">
              Simpan
            </v-btn>
          </v-form>
          <v-dialog v-model="loading" hide-overlay persistent width="250">
            <v-card class="indigo" dark>
              <v-card-text>
                silahkan tunggu...
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_USER, LIST_AVATAR } from "../../graphql/graphql";
import { mapState } from "vuex";
import Swal from "sweetalert2";
import { EDIT_STUDENT } from "../../graphql/Student";
import ChooseAvatar from "../../components/Student/Modal/chooseAvatar.vue";

export default {
  apollo: {
    profile: {
      query: GET_USER,
      error(error) {
        return error.graphQLErrors[0].message;
      }
    },
    allAvatar: {
      query: LIST_AVATAR
    }
  },
  name: "updateProfile",

  components: {
    ChooseAvatar
  },
  created() {},
  computed: {
    loadData() {
      return this.$apollo.queries.profile.loading;
    },
    ...mapState(["precentage"]),
    fotoDummy() {
      const pp = "https://dashboard.anargya.id/img/fotoprofile.jpg";
      return pp;
    },
    avatar() {
      let pp = null;
      if (this.profile.data_avatar) {
        pp = `${process.env.VUE_APP_GRAPHQL}/avatar/${this.profile.data_avatar.file}`;
      }
      return pp;
    },
    myAvatar() {
      let data = null;
      if (this.profile) {
        data = this.profile.avatar_peserta.map(x => {
          return x;
        });
      }
      return data;
    },
    dataAva() {
      let data = null;
      if (this.profile) {
        return (data = this.profile.avatar_peserta);
      }
      return data;
    }
  },
  data() {
    return {
      value: null,
      profile: null,
      allAvatar: null,
      date: new Date().toISOString().substr(0, 10),
      menu1: false,
      avatarDialog: false,
      // v-model nama
      sex: [
        { state: "Laki - laki", val: "Lakilaki" },
        { state: "Perempuan", val: "Perempuan" }
      ],
      agama: [
        { state: "Islam", val: "Islam" },
        { state: "Protestan", val: "Protestan" },
        { state: "Katholik", val: "Katholik" },
        { state: "Hindu", val: "Hindu" },
        { state: "Budha", val: "Budha" },
        { state: "Khonghucu", val: "Khonghucu" },
        { state: "Lain-lain", val: "Lain-lain" },
      ],
      dialog: false,
      loading: false
    };
  },
  methods: {
    dialogFalse() {
      this.avatarDialog = false;
    },
    profileRefetch() {
      this.$apollo.queries.profile.refetch();
      this.dialogFalse();
    },
    updatePeserta() {
      const id = parseInt(this.profile.id);
      const id_profile = parseInt(this.profile.student.id);
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: EDIT_STUDENT,
          variables: {
            id: id,
            nama_lengkap: this.profile.nama_lengkap,
            tipe_avatar: this.profile.tipe_avatar,
            email: this.profile.email,
            id_profile: id_profile,
            connect: id,
            no_peserta: this.profile.student.no_peserta,
            nik: this.profile.student.nik,
            nip: this.profile.student.nip,
            golongan: this.profile.student.golongan,
            pangkat: this.profile.student.pangkat,
            jabatan: this.profile.student.jabatan,
            unit_kerja: this.profile.student.unit_kerja,
            instansi: this.profile.student.instansi,
            jenis_kelamin: this.profile.student.jenis_kelamin,
            agama: this.profile.student.agama,
            tempat_lahir: this.profile.student.tempat_lahir,
            tanggal_lahir: this.profile.student.tanggal_lahir,
            alamat_tinggal: this.profile.student.alamat_tinggal,
            alamat_ktp: this.profile.student.alamat_ktp
          }
        })
        .then(() => {
          this.profileRefetch();
          this.loading = false;
          let data = this.profile.student;
          let allData = Object.keys(data).length;
          let count = 0;
          for (let q in data) {
            if (data[q] !== null) {
              count++;
            }
          }
          let percent = Math.ceil((count / allData) * 100);
          console.log(percent);
          this.$store.commit("PRECENTAGE", percent);
          Swal.fire({
            icon: "success",
            title: "Profile telah terupdate",
            showConfirmButton: false,
            timer: 3000,
            position: "top",
            toast: true
          }).then(() => {
            this.$router.push("/profile");
            // this.profileRefetch();
          });
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
          return err;
        });
    }
  }
};
</script>

<style>
.container-update {
  width: 100%;
  background: #f4f6f9;
  min-height: 90vh;
  padding: 10px;
}
.card-profile {
  width: 25%;
  display: block;
  height: min-content;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.22);
  margin: 10px;
  padding: 10px;
}
.temp-form-edit {
  width: 75%;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.22);
  padding: 10px;
  margin: 10px;
}
.temp-rafatar {
  width: 100%;
  display: flex;
  justify-content: center;
}
.tag {
  position: absolute;
  display: inline-block;
  padding: 10px 15px;
  background: #53af50;
  border-radius: 5px;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.22);
  color: #fff;
  margin-top: -30px;
}
.point {
  color: #5c73f2;
}
.dva {
  object-fit: cover;
}
</style>
