import gql from "graphql-tag";

export const GET_USER = gql`
  query {
    profile {
      id
      name
      nama_lengkap
      email
      role_id
      avatar
      tipe_avatar
      data_avatar {
        id
        file
        poin
      }
      avatar_peserta {
        id
        avatar_id
        tipe
      }
      kasus_peserta {
        id
        status
        fase
        harga
        modulKasus {
          id
          modul_id
          fase
          narasi
          file
          kasus
          chart
        }
      }
      pembimbing {
        id
        user_id
        tempat_lahir
        tanggal_lahir
        nik
        nip
        golongan
        pangkat
        jabatan
        unit_kerja
        instansi
        bidang_keahlian
        pendidikan_terakhir
        jenis_kelamin
        agama
        alamat_tinggal
        alamat_ktp
      }
      student {
        id
        user_id
        tempat_lahir
        tanggal_lahir
        no_peserta
        nik
        nip
        golongan
        pangkat
        jabatan
        unit_kerja
        instansi
        jenis_kelamin
        agama
        alamat_tinggal
        alamat_ktp
        poin
        config
      }
      grup {
        id
        config
        pelatihan {
          id
          start_date_individu
          end_date_individu
          start_date_kelompok
          end_date_kelompok
          game
          modul {
            id
            narasi_kelompok
            narasi_individu
            tipe
            modul_tugas {
              id
              modul_id
              modul_kasus_id
              judul
              fase
              keterangan
            }
            modul_kasus {
              id
              modul_id
              fase
              narasi
              file
              kasus
              chart
            }
            berita {
              id
              modul_id
              judul
              ringkasan_berita
              isi_berita
              link
            }
          }
        }
      }
      data_badges {
        id
        badge_id
        badge {
          id
          title
          file
          keterangan
        }
      }
    }
  }
`;

// READ STUDENT
export const LIST_AVATAR = gql`
  query {
    allAvatar {
      id
      file
      poin
    }
  }
`;

// LOG
export const ACTIVITY = gql`
  query($page: Int!, $first: Int!) {
    allActivity(page: $page, first: $first) {
      data {
        id
        tipe
        user_id
        keterangan
        created_at
      }
    }
  }
`;

//avatar private
export const AVATAR_PRIVATE = gql`
  query {
    allAvatarPrivate {
      id
      file
      poin
    }
  }
`;

export const GET_NILAI_INDIVIDU = gql`
  query {
    profile {
      id
      name
      student {
        id
        nilai_objektif_teacher
        nilai_objektif_mentor
      }
      grup {
        id
        nilai_objektif_teacher
        nilai_objektif_mentor
        config
      }
    }
  }
`;

export const JUST_REFETCH_PROFILE = gql`
  query {
    profile {
      id
    }
  }
`;
